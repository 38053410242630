import { defineMessages } from 'react-intl';

const scope = 'app';

export default defineMessages({
  hello: {
    id: `${scope}.hello`,
    defaultMessage: 'Salut!'
  },
  youIdentifyAs: {
    id: `${scope}.youIdentifyAs`,
    defaultMessage: 'Vous vous identifier comme?'
  },
  mr: {
    id: `${scope}.mr`,
    defaultMessage: 'Monsieur'
  },
  mm: {
    id: `${scope}.mm`,
    defaultMessage: 'Madame'
  },
  youAre: {
    id: `${scope}.youAre`,
    defaultMessage: 'Vous êtes?'
  },
  yourFullName: {
    id: `${scope}.yourFullName`,
    defaultMessage: 'Votre nom complet'
  },
  nameNoNumbers: {
    id: `${scope}.nameNoNumbers`,
    defaultMessage:
      'Votre nom ne doit contenir que des caractères alphabétiques'
  },
  yourEmail: {
    id: `${scope}.yourEmail`,
    defaultMessage: 'Votre adresse e-mail'
  },
  yourEmailPlaceholder: {
    id: `${scope}.yourEmailPlaceholder`,
    defaultMessage: 'example@domain.com'
  },
  emailValidation: {
    id: `${scope}.emailValidation`,
    defaultMessage: "L'adresse doit être de format valide"
  },
  idCard: {
    id: `${scope}.idCard`,
    defaultMessage: "Votre numéro de carte d'identité nationale?"
  },
  idCardDeliveryDate: {
    id: `${scope}.idCardDeliveryDate`,
    defaultMessage:
      "La date de livraison de votre numéro de carte d'identité nationale?"
  },
  idValidation: {
    id: `${scope}.idValidation`,
    defaultMessage: 'Le numéro de votre CIN doit être de format valide'
  },
  phoneNumber: {
    id: `${scope}.phoneNumber`,
    defaultMessage: 'Votre numéro de téléphone?'
  },
  phoneValidation: {
    id: `${scope}.phoneValidation`,
    defaultMessage: 'Votre numéro doit être de format valide'
  },
  yourAddress: {
    id: `${scope}.yourAddress`,
    defaultMessage: 'Votre adresse?'
  },
  yourAddressPlaceholder: {
    id: `${scope}.yourAddressPlaceholder`,
    defaultMessage: 'Votre adresse'
  },
  typeQuestion: {
    id: `${scope}.typeQuestion`,
    defaultMessage: 'Vous représenter?'
  },
  typeQuestionCompany: {
    id: `${scope}.typeQuestionCompany`,
    defaultMessage: 'Votre entreprise est une?'
  },
  company: {
    id: `${scope}.company`,
    defaultMessage: 'Société'
  },
  association: {
    id: `${scope}.association`,
    defaultMessage: 'Association'
  },
  businessName: {
    id: `${scope}.businessName`,
    defaultMessage: 'Quel est le nom de votre {type}?'
  },
  businessNamePlaceholder: {
    id: `${scope}.businessNamePlaceholder`,
    defaultMessage: 'Nom de votre {type}?'
  },
  businessNameValidation: {
    id: `${scope}.businessNameValidation`,
    defaultMessage: 'Le nom ne doit contenir que des caractères alphabétiques'
  },
  wip: {
    id: `${scope}.wip`,
    defaultMessage: 'Votre {type}, est-t-elle en cours de constitution?'
  },
  uid: {
    id: `${scope}.uid`,
    defaultMessage: "Quel est l'identifiant unique de votre {type}?"
  },
  uidPlaceholder: {
    id: `${scope}.uidPlaceholder`,
    defaultMessage: 'Identifiant unique'
  },
  capital: {
    id: `${scope}.capital`,
    defaultMessage: 'Quel est le capital de votre {type} en TND?'
  },
  capitalValidation: {
    id: `${scope}.capitalValidation`,
    defaultMessage: 'Le chiffre doit être strictement positif'
  },
  yourBusinessAddress: {
    id: `${scope}.yourBusinessAddress`,
    defaultMessage: 'Le siège social de votre {type} est?'
  },
  yourBusinessAddressPlaceholder: {
    id: `${scope}.yourBusinessAddressPlaceholder`,
    defaultMessage: 'Siège social'
  },
  yourPack: {
    id: `${scope}.yourPack`,
    defaultMessage: 'Votre pack de choix?'
  },
  yes: {
    id: `${scope}.yes`,
    defaultMessage: 'Oui'
  },
  no: {
    id: `${scope}.no`,
    defaultMessage: 'Non'
  },
  starterPack: {
    id: `${scope}.starterPack`,
    defaultMessage: 'Starter Pack | {amount}DT/{duration} HT'
  },
  proPack: {
    id: `${scope}.proPack`,
    defaultMessage: 'Pro Pack | {amount}DT/{duration} HT'
  },
  premiumPack: {
    id: `${scope}.premiumPack`,
    defaultMessage: 'Premium Pack | {amount}DT/{duration} HT'
  },
  month: {
    id: `${scope}.month`,
    defaultMessage: 'mois'
  },
  startAt: {
    id: `${scope}.startAt`,
    defaultMessage: 'Vous voulez commencer votre domiciliation à partir de?'
  },
  next: {
    id: `${scope}.next`,
    defaultMessage: 'Continuer'
  },
  previous: {
    id: `${scope}.previous`,
    defaultMessage: 'Étape précédente'
  },
  perfect: {
    id: `${scope}.perfect`,
    defaultMessage: 'Parfait!'
  },
  youCanDownloadContract: {
    id: `${scope}.youCanDownloadContract`,
    defaultMessage: 'Vous pouvez télécharger votre contrat.'
  },
  itsDone: {
    id: `${scope}.itsDone`,
    defaultMessage: "C'est terminé!"
  },
  download: {
    id: `${scope}.download`,
    defaultMessage: 'Télécharger'
  },
  pleaseWait: {
    id: `${scope}.pleaseWait`,
    defaultMessage: 'Patientez S.V.P...'
  }
});
