import React, { useState } from 'react';
import { Col, Button, Progress } from 'shards-react';
import { useIntl } from 'gatsby-plugin-intl';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { pdf } from '@react-pdf/renderer';
import validator from 'validator';
import axios from 'axios';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { Input, RadioGroup, DatePicker } from '../ui-lib';
import messages from '../translations/contract';
import { PDFDocument as Contract } from '../utils/index';

const App = () => {
  const intl = useIntl();
  const preSelectedPAck =
    typeof window !== 'undefined'
      ? new URLSearchParams(window.location.search).get('pack')
      : undefined;

  const [currentStep, setCurrentStep] = useState('identifer-step');
  const totalSteps = 16;

  const [loadingPDF, setLoadingPDF] = useState(false);

  const StepContainer = props => {
    const {
      step: propStep,
      children,
      disableNext,
      title,
      stepName,
      nextStep: propNextStep,
      previousStep
    } = props;
    return (
      <>
        {currentStep === stepName && (
          <div className="form-step-container">
            <div className="fade-in">
              <h5>{title}</h5>
              {children}
            </div>
            <div className="fix-buttons-to-bottom w-100">
              <Progress theme="success" value={(propStep / totalSteps) * 100} />
              <div className="d-flex justify-content-between text-right mt-4">
                <Button
                  className="app-btn-secondary"
                  theme="light"
                  disabled={propStep < 2}
                  onClick={() => {
                    setCurrentStep(previousStep);
                  }}
                >
                  {intl.formatMessage(messages.previous)}
                </Button>
                <Button
                  className="app-btn-primary"
                  theme="success"
                  onClick={() => {
                    setCurrentStep(propNextStep);
                  }}
                  disabled={disableNext}
                >
                  {intl.formatMessage(messages.next)}
                </Button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const blobToBase64 = blob => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
      reader.onloadend = () => {
        resolve(reader.result.split(',')[1]);
      };
    });
  };

  return (
    <Layout className="app-container d-flex justify-content-center">
      <SEO title="Contract" />
      <Col>
        <div className="form-fields-container">
          <Formik
            validateOnChange
            initialValues={{
              identifer: undefined,
              clientName: undefined,
              email: undefined,
              idCard: undefined,
              idCardDate: undefined,
              phoneNumber: undefined,
              delDate: undefined,
              address: undefined,
              type: undefined,
              companyType: undefined,
              wip: undefined,
              uid: undefined,
              capital: undefined,
              businessName: undefined,
              businessAddress: undefined,
              pack: preSelectedPAck || undefined,
              startDate: undefined
            }}
            validationSchema={Yup.object().shape({
              clientName: Yup.string().matches(
                /^[a-zàâçéèêëîïôûùüÿñæœ .-]*$/i,
                intl.formatMessage(messages.nameNoNumbers)
              ),
              email: Yup.string().email(
                intl.formatMessage(messages.emailValidation)
              ),
              idCard: Yup.string().matches(
                /^([0-1])([0-9]{7})$/i,
                intl.formatMessage(messages.idValidation)
              ),
              phoneNumber: Yup.string().test(
                'phoneNumber',
                intl.formatMessage(messages.phoneValidation),
                value => (value ? validator.isMobilePhone(value) : true)
              ),
              businessNameValidation: Yup.string().matches(
                /^[a-zàâçéèêëîïôûùüÿñæœ .-]*$/i,
                intl.formatMessage(messages.phoneValidation)
              ),
              capital: Yup.number().min(
                1,
                intl.formatMessage(messages.capitalValidation)
              )
            })}
          >
            {({ values, errors }) => (
              <>
                <StepContainer
                  step={1}
                  stepName="identifer-step"
                  nextStep="client-name-step"
                  disableNext={!values.identifer}
                  title={intl.formatMessage(messages.youIdentifyAs)}
                >
                  <RadioGroup
                    name="identifer"
                    options={[
                      { label: intl.formatMessage(messages.mm), value: 'mm' },
                      { label: intl.formatMessage(messages.mr), value: 'mr' }
                    ]}
                  />
                </StepContainer>
                <StepContainer
                  step={2}
                  stepName="client-name-step"
                  nextStep="client-email-step"
                  previousStep="identifer-step"
                  title={intl.formatMessage(messages.youAre)}
                  disableNext={
                    !values.clientName ||
                    values.clientName.length < 5 ||
                    errors.clientName !== undefined
                  }
                >
                  <Input
                    placeholder={intl.formatMessage(messages.yourFullName)}
                    className="mb-3"
                    name="clientName"
                  />
                </StepContainer>
                <StepContainer
                  step={3}
                  stepName="client-email-step"
                  nextStep="id-card-step"
                  previousStep="client-name-step"
                  title={intl.formatMessage(messages.yourEmail)}
                  disableNext={!values.email || errors.email !== undefined}
                >
                  <Input
                    placeholder={intl.formatMessage(
                      messages.yourEmailPlaceholder
                    )}
                    className="mb-3"
                    name="email"
                  />
                </StepContainer>
                <StepContainer
                  step={4}
                  stepName="id-card-step"
                  nextStep="id-card-date-step"
                  previousStep="client-email-step"
                  title={intl.formatMessage(messages.idCard)}
                  disableNext={!values.idCard || errors.idCard !== undefined}
                >
                  <Input
                    placeholder="01234567"
                    className="mb-3"
                    name="idCard"
                  />
                </StepContainer>
                <StepContainer
                  step={5}
                  stepName="id-card-date-step"
                  nextStep="phone-number-step"
                  previousStep="id-card-step"
                  title={intl.formatMessage(messages.idCardDeliveryDate)}
                  disableNext={!values.idCardDate}
                >
                  <DatePicker
                    name="idCardDate"
                    maxDate={new Date()}
                    format="dd/MM/yyyy"
                  />
                </StepContainer>
                <StepContainer
                  step={6}
                  stepName="phone-number-step"
                  nextStep="client-address-step"
                  previousStep="id-card-date-step"
                  title={intl.formatMessage(messages.phoneNumber)}
                  disableNext={
                    !values.phoneNumber || errors.phoneNumber !== undefined
                  }
                >
                  <Input
                    placeholder="12345678"
                    className="mb-3"
                    name="phoneNumber"
                  />
                </StepContainer>
                <StepContainer
                  step={7}
                  stepName="client-address-step"
                  nextStep="type-step"
                  previousStep="phone-number-step"
                  title={intl.formatMessage(messages.yourAddress)}
                  disableNext={
                    !values.address ||
                    values.address.length < 10 ||
                    errors.address !== undefined
                  }
                >
                  <Input
                    placeholder={intl.formatMessage(
                      messages.yourAddressPlaceholder
                    )}
                    className="mb-3"
                    name="address"
                  />
                </StepContainer>
                <StepContainer
                  step={8}
                  stepName="type-step"
                  nextStep={
                    values.type === 'association'
                      ? 'business-name-step'
                      : 'company-type-step'
                  }
                  previousStep="client-address-step"
                  title={intl.formatMessage(messages.typeQuestion)}
                  disableNext={!values.type}
                >
                  <RadioGroup
                    name="type"
                    options={[
                      {
                        label: intl.formatMessage(messages.company),
                        value: 'company'
                      },
                      {
                        label: intl.formatMessage(messages.association),
                        value: 'association'
                      }
                    ]}
                  />
                </StepContainer>
                <StepContainer
                  step={9}
                  stepName="company-type-step"
                  nextStep="business-name-step"
                  previousStep="type-step"
                  title={intl.formatMessage(messages.typeQuestionCompany)}
                  disableNext={!values.companyType}
                >
                  <RadioGroup
                    name="companyType"
                    options={[
                      {
                        label: 'SA',
                        value: 'SA'
                      },
                      {
                        label: 'SARL',
                        value: 'SARL'
                      },
                      {
                        label: 'SUARL',
                        value: 'SUARL'
                      }
                    ]}
                  />
                </StepContainer>
                <StepContainer
                  step={10}
                  stepName="business-name-step"
                  nextStep="wip-step"
                  previousStep={
                    values.type === 'association'
                      ? 'type-step'
                      : 'company-type-step'
                  }
                  title={intl.formatMessage(messages.businessName, {
                    type:
                      values.type === 'association'
                        ? intl.formatMessage(messages.association)
                        : intl.formatMessage(messages.company)
                  })}
                  disableNext={
                    !values.businessName ||
                    values.businessName.length < 3 ||
                    errors.businessName !== undefined
                  }
                >
                  <Input
                    placeholder={intl.formatMessage(
                      messages.businessNamePlaceholder,
                      {
                        type:
                          values.type === 'association'
                            ? intl.formatMessage(messages.association)
                            : intl.formatMessage(messages.company)
                      }
                    )}
                    className="mb-3"
                    name="businessName"
                  />
                </StepContainer>
                <StepContainer
                  step={11}
                  stepName="wip-step"
                  nextStep={values.wip === true ? 'pack-step' : 'uid-step'}
                  previousStep="business-name-step"
                  title={intl.formatMessage(messages.wip, {
                    type:
                      values.type === 'association'
                        ? intl.formatMessage(messages.association)
                        : intl.formatMessage(messages.company)
                  })}
                  disableNext={values.wip === undefined}
                >
                  <RadioGroup
                    name="wip"
                    options={[
                      {
                        label: intl.formatMessage(messages.yes),
                        value: true
                      },
                      {
                        label: intl.formatMessage(messages.no),
                        value: false
                      }
                    ]}
                  />
                </StepContainer>
                <StepContainer
                  step={12}
                  stepName="uid-step"
                  nextStep="capital-step"
                  previousStep="wip-step"
                  title={intl.formatMessage(messages.uid, {
                    type:
                      values.type === 'association'
                        ? intl.formatMessage(messages.association)
                        : intl.formatMessage(messages.company)
                  })}
                  disableNext={
                    values.uid === undefined || values.uid.length < 4
                  }
                >
                  <Input
                    placeholder={intl.formatMessage(messages.uidPlaceholder)}
                    className="mb-3"
                    name="uid"
                  />
                </StepContainer>
                <StepContainer
                  step={13}
                  stepName="capital-step"
                  nextStep="business-address-step"
                  previousStep="uid-step"
                  title={intl.formatMessage(messages.capital, {
                    type:
                      values.type === 'association'
                        ? intl.formatMessage(messages.association)
                        : intl.formatMessage(messages.company)
                  })}
                  disableNext={
                    values.capital === undefined || errors.capital !== undefined
                  }
                >
                  <Input
                    placeholder="0"
                    className="mb-3"
                    name="capital"
                    type="number"
                  />
                </StepContainer>
                <StepContainer
                  step={14}
                  stepName="business-address-step"
                  nextStep="pack-step"
                  previousStep="capital-step"
                  title={intl.formatMessage(messages.yourBusinessAddress, {
                    type:
                      values.type === 'association'
                        ? intl.formatMessage(messages.association)
                        : intl.formatMessage(messages.company)
                  })}
                  disableNext={
                    !values.businessAddress ||
                    values.businessAddress.length < 10 ||
                    errors.businessAddress !== undefined
                  }
                >
                  <Input
                    placeholder={intl.formatMessage(
                      messages.yourBusinessAddressPlaceholder
                    )}
                    className="mb-3"
                    name="businessAddress"
                  />
                </StepContainer>
                <StepContainer
                  step={15}
                  stepName="pack-step"
                  nextStep="start-date-step"
                  previousStep={
                    values.wip === true ? 'wip-step' : 'business-address-step'
                  }
                  title={intl.formatMessage(messages.yourPack)}
                  disableNext={!values.pack}
                >
                  <RadioGroup
                    name="pack"
                    options={[
                      {
                        label: intl.formatMessage(messages.starterPack, {
                          amount: 69,
                          duration: intl.formatMessage(messages.month)
                        }),
                        value: 'starter-pack'
                      },
                      {
                        label: intl.formatMessage(messages.proPack, {
                          amount: 99,
                          duration: intl.formatMessage(messages.month)
                        }),
                        value: 'pro-pack'
                      },
                      {
                        label: intl.formatMessage(messages.premiumPack, {
                          amount: 119,
                          duration: intl.formatMessage(messages.month)
                        }),
                        value: 'premium-pack'
                      }
                    ]}
                  />
                </StepContainer>
                <StepContainer
                  step={16}
                  stepName="start-date-step"
                  nextStep="end-step"
                  previousStep="pack-step"
                  title={intl.formatMessage(messages.startAt)}
                  disableNext={!values.startDate}
                >
                  <DatePicker
                    name="startDate"
                    minDate={new Date()}
                    maxDetail="year"
                  />
                </StepContainer>
                {currentStep === 'end-step' && (
                  <div className="text-center">
                    <h3 className="fade-in">
                      {intl.formatMessage(messages.perfect)}
                    </h3>
                    <div className="delayed-fade-in">
                      {intl.formatMessage(messages.youCanDownloadContract)}
                    </div>
                    <div className="delayed-fade-in">
                      <Button
                        className="delayed-fade-in app-btn-primary mt-4"
                        theme="success"
                        disabled={loadingPDF}
                        onClick={async () => {
                          setLoadingPDF(true);
                          const blob = await pdf(
                            <Contract values={values} />
                          ).toBlob();
                          const link = document.createElement('a');
                          link.href = URL.createObjectURL(blob);
                          link.download = true;
                          link.target = 'blank';
                          link.download = `contrat_domiciliation_-_${values.clientName
                            .replace(/\s+/g, '_')
                            .toLowerCase()}`;
                          document.body.append(link);
                          // prepare attachment for emails
                          const base64PDF = await blobToBase64(blob);
                          try {
                            await axios.post(
                              '/.netlify/functions/sendcontract',
                              { ...values, contract: base64PDF }
                            );
                          } catch (err) {
                            // do nothing
                          }
                          try {
                            await axios.post(
                              '/.netlify/functions/notifyadmins',
                              { ...values, contract: base64PDF }
                            );
                          } catch (err) {
                            // do nothing
                          }
                          link.click();
                          link.remove();
                          setLoadingPDF(false);
                          setTimeout(
                            () => URL.revokeObjectURL(link.href),
                            7000
                          );
                        }}
                      >
                        <FontAwesomeIcon icon="arrow-down" className="mr-2" />
                        {intl.formatMessage(
                          !loadingPDF ? messages.download : messages.pleaseWait
                        )}
                      </Button>
                    </div>
                  </div>
                )}
              </>
            )}
          </Formik>
        </div>
      </Col>
    </Layout>
  );
};

export default App;
